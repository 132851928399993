<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        任务<Input clearable placeholder="输入任务 搜索" class="search-input"
               v-model="queryinfo.taskname"
               style="width:150px"
               @on-enter="namechange"
               @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        用户名<Input clearable placeholder="输入用户名 搜索" class="search-input"
               v-model="queryinfo.created_user"
                  style="width:150px"
               @on-enter="namechange"
               @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        任务分类<Input clearable placeholder="输入任务分类 搜索" class="search-input"
                  v-model="queryinfo.customtype_name"
                   style="width:150px"
                  @on-enter="namechange"
                  @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        任务类型
        <Select v-model="queryinfo.tasktype" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in tasktypelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        模块名称
        <Select v-model="queryinfo.modular" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in modulelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        状态
        <Select v-model="queryinfo.state" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in statelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <Button type="primary" class="search-btn"  @click="getdata(queryinfo)">搜索-刷新</Button>
        <slot name="new_btn">
          <Button type="primary" class="search-btn"  @click="showaddtask = true">添加</Button>
        </slot>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
    </Card>

    <template>
      <Page :current="page"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
    <Drawer
        :closable="false"
        v-model="showaddtask"
        title="请添加你要执行的任务"
        width="800">
      <Form ref="formValidate1" :model="addtaskform" :rules="ruleaddtask" :label-width="80">
        <FormItem label="任务名称" prop="taskname">
          <Input v-model="addtaskform.taskname" placeholder="请输入任务名称"></Input>
        </FormItem>
        <FormItem label="任务类型" prop="tasktype" style="width:100%">
          <Select v-model="addtaskform.tasktype" placeholder="请选择任务类型" @on-change="changetasktype_to_args">
            <Option v-for="item in tasktypelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <template v-if="addtaskform.tasktype == 1">
          <FormItem label="模块选择" prop="modular">
            <Select v-model="addtaskform.modular" placeholder="选择指定模块" @on-change="changemodule">
              <Option  v-for="item in modulelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </FormItem>
        </template>
        <template v-if="addtaskform.tasktype == 2">
          <FormItem label="剧本使用" prop="playbook_name">
            <Select v-model="addtaskform.playbook_name" placeholder="选择指定playbook剧本"
                    filterable
                    :remote-method="selectplaybook"
                    :loading="loadingplaybook">
              <Option  v-for="item in playbooklist" :value="item.name" :key="item.name">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </template>
        <FormItem label="参数" prop="args">
          <Input v-model="addtaskform.args"  type="textarea" placeholder="请输入参数" :autosize="{minRows: 3}"></Input>
        </FormItem>
        <FormItem label="任务分类" prop="assets">
          <Select v-model="addtaskform.customtype_name" placeholder="请输入分类" clearable>
            <Option  v-for="item in customtypelist" :value="item.name" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <template v-if="addtaskform.customtype_name === '切任务'">
          <Row :gutter="32">
            <Col span="8">
              <FormItem label="source" prop="source">
                <Select v-model="addtaskform.source" placeholder="请指定源"
                        filterable>
                  <Option  v-for="item in streelist" :value="item" :key="item">{{ item }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="target" prop="target">
                <Select v-model="addtaskform.target" placeholder="请指定目标"
                        filterable>
                  <Option  v-for="item in streelist" :value="item" :key="item.index">{{ item }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </template>
        <FormItem label="机器" prop="hosts">
          <Input v-model="addtaskform.hosts" type="textarea" placeholder="请输入 机器" :autosize="{minRows: 2}"></Input>
        </FormItem>
        <FormItem label="步长" prop="stepsize">
          <InputNumber  v-model="addtaskform.stepsize" placeholder="请输入步长" :max="20" :min="1" style="width: 100%"></InputNumber >
        </FormItem>
        <FormItem>
          <Button @click="addtaskvalidate('formValidate1')" type="primary" >提交</Button>
          <Button @click="handleReset('formValidate1')" style="position: absolute;left: 10%;">重置</Button>
        </FormItem>
      </Form>
    </Drawer>
    <Drawer
        :closable="false"
        v-model="showupdatatask"
        title="请更新你要执行的任务"
        width="800">
      <Form ref="formValidate" :model="updatetaskform" :rules="ruleaddtask" :label-width="80">
        <FormItem label="任务名称" prop="taskname">
          <Input v-model="updatetaskform.taskname" placeholder="请输入任务名称"></Input>
        </FormItem>
        <FormItem label="任务类型" prop="tasktype" style="width:100%">
          <Select v-model="updatetaskform.tasktype" placeholder="请选择任务类型" @on-change="changetasktype_to_args">
            <Option v-for="item in tasktypelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <template v-if="updatetaskform.tasktype == 1">
          <FormItem label="模块选择" prop="modular">
            <Select v-model="updatetaskform.modular" placeholder="选择指定模块" @on-change="changeupdatemodule">
              <Option  v-for="item in modulelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </FormItem>
        </template>
        <template v-if="updatetaskform.tasktype == 2">
          <FormItem label="剧本使用" prop="playbook_name">
            <Select v-model="updatetaskform.playbook_name" placeholder="选择指定playbook剧本">
              <Option  v-for="item in playbooklist" :value="item.name" :key="item.name">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </template>
        <FormItem label="参数" prop="args">
          <Input v-model="updatetaskform.args"  type="textarea" placeholder="请输入参数" :autosize="{minRows: 3}"></Input>
        </FormItem>
        <FormItem label="任务分类" prop="assets">
          <Select v-model="updatetaskform.customtype_name" placeholder="请输入分类" @on-change="changemodule" clearable>
            <Option  v-for="item in customtypelist" :value="item.name" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <template v-if="updatetaskform.customtype_name === '切任务'">
          <Row :gutter="32">
            <Col span="8">
              <FormItem label="source" prop="source">
                <Select v-model="updatetaskform.source" placeholder="请指定源"
                        filterable>
                  <Option  v-for="item in streelist" :value="item" :key="item">{{ item }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="target" prop="target">
                <Select v-model="updatetaskform.target" placeholder="请指定目标"
                        filterable>
                  <Option  v-for="item in streelist" :value="item" :key="item.index">{{ item }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </template>
        <FormItem label="机器" prop="hosts">
          <Input v-model="updatetaskform.hosts" type="textarea" placeholder="请输入 机器" :autosize="{minRows: 2}"></Input>
        </FormItem>
        <FormItem label="步长" prop="stepsize">
          <InputNumber  v-model="updatetaskform.stepsize" placeholder="请输入步长" :max="20" :min="1" style="width: 100%"></InputNumber >
        </FormItem>
        <FormItem>
          <Button @click="updatetaskvalidate('formValidate')" type="primary" >提交</Button>
          <Button @click="cancel" style="position: absolute;left: 10%;">取消</Button>
        </FormItem>
      </Form>
    </Drawer>
    <Modal
            v-model="showdelete"
            title="删除任务信息"
            @on-ok="deletetasklog(deltasklog.id)"
            @on-cancel="canceldelete">
      <p>任务id： {{deltasklog.id}}</p>
      <p>任务名称： {{deltasklog.taskname}}</p>
    </Modal>
    <Modal
        v-model="showpublish"
        title="确定发布服务"
        @on-ok="publish(taskinfo.id)"
        @on-cancel="canceldelete">
      <p>任务id： {{taskinfo.id}}</p>
      <p>任务名称： {{taskinfo.taskname}}</p>
    </Modal>
  </div>
</template>

<script>
  import {get,post,put,del} from "@/api/http.js";
  import moment from "moment";
  export default {
    name: "index",
    data () {
      return {
        data: [],
        taskinfo: {},
        loadingplaybook: false,
        loadingtype: false,
        showpublish: false,
        page: 1,
        pagesizeopts: [10, 20, 30, 40],
        pagesize: 10,
        total: 0,
        showdelete: false,
        showstop: false,
        options2: [],
        streelist: [],
        columns: [
          {type: 'selection', width: 60, align: 'center'}, {title: '任务名称', key: 'taskname', width: 200},
          {title: '创建用户', key: 'created_user', align: 'center',width: 120},
          {title: '任务类型', key: 'tasktype', width: 100,
            render: (h,{row}) =>{
              let tasktypeColor = {
                "1": ['模块', '#2db7f5'],
                "2": ['剧本', '#19be6b']
              }
              return h("Badge",{
                props: {
                  color: tasktypeColor[row.tasktype][1],
                  text:  tasktypeColor[row.tasktype][0]
                }
              })
            }
          },
          {title: '模块名称', key: 'modular', align: 'center', width: 120},
          {title: '任务分类', key: 'customtype_name'},
          {title: '剧本名', key: 'playbook_name', align: 'center', width: 120,},
          {title: '参数', key: 'args', align: 'center', ellipsis: true},
          {title: '创建时间', key: 'created_at', ellipsis: true, width: 200,
            render: (h,{row}) =>{
              return h('div',this.time_change(row.created_at))
            }
          },
          {title: '状态', key: 'state', width: 100,
            render: (h,{row}) =>{
              let stateColor = {
                "0": ['失败', '#ed4014'],
                "1": ['成功', '#19be6b'],
                "2": ['警告', '#ff9900'],
                "3": ['异常', '#ed4014'],
                "4": ['已终止', '#ff9900'],
                "5": ['无状态', '#2db7f5'],
                "8": ['等待中', '#ff9900'],
                "9": ['进行中', '#ff9900'],
                "10": ['已停止', '#19be6b']
              }
              return h("Badge",{
                props: {
                  color: stateColor[row.state][1],
                  text:  stateColor[row.state][0]
                }
              })
            }
          },
          {title: '操 作', key: 'action', width: 230, align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.showpublishdrawer(params.row)
                    }
                  }
                }, ' 发布 |'),
                h('a', {
                  on: {
                    click: () => {
                      this.showdetails(params.row)
                    }
                  }
                }, ' 详情 |'),
                h('a', {
                  on: {
                    click: () => {
                      this.showupdata(params.row)
                    }
                  }
                }, ' 编辑 |'),
                h('a', {
                  on: {
                    click: () => {
                      this.isdelete(params.row)
                    }
                  }
                }, ' 删除'),
              ]);
            }
          }
        ],
        showaddtask: false,
        showupdatatask: false,
        tasktypelist: [
          {value: 1, label: '模 块'},
          {value: 2, label: 'Playbook'}
        ],
        playbooklist: [],

        args:{
          shell: 'uptime',
          yum: "name=httpd state=present",
          copy: "src=/root/my.txt dest=/tmp/test.txt",
          file: "path=/tmp/my.cn mode=600 state=touch",
          cron: "name='sync time' minute=*/3 hour=* day=* month=* weekday=* job='/usr/sbin/ntpdate window.time.com'",
          wget: "url=https://my.com/file.tar.gz dest=/tmp",
          service: "name=httpd state=restarted enabled=yes"
        },
        customtypelist: [],
        statelist: [
          {value: "0",label:"失败"},
          {value: "1",label:"成功"},
          {value: "2",label:"警告"},
          {value: "3",label:"异常"},
          {value: "4",label:"已终止"},
          {value: "8",label:"等待中"},
          {value: "9",label:"进行中"},
          {value: "10",label:"已停止"},
        ],
        modulelist: [
          // {
          //   value: 'ping',
          //   label: 'ping'
          // },
          {
            value: 'shell',
            label: 'shell'
          },
          // {
          //   value: 'yum',
          //   label: 'yum'
          // },
          {
            value: 'script',
            label: 'script'
          },
          // {
          //   value: 'copy',
          //   label: 'copy'
          // },
          // {
          //   value: 'file',
          //   label: 'file'
          // },
          // {
          //   value: 'cron',
          //   label: 'cron'
          // },
          // {
          //   value: 'wget',
          //   label: 'wget'
          // },
          // {
          //   value: 'service',
          //   label: 'service'
          // },
        ],
        deltasklog: {},
        addtaskform: {
          state: 5,
          stepsize: 1,
        },
        updatetaskform: {},
        queryname:{
          name:"",
          pagesize: 100,
        },
        queryplaybookinfo: {
          page: 1,
          pagesize: 10,
          name:"",
        },
        queryinfo: {
          page: 1,
          pagesize: 10,
          taskname:"",
          created_user:"",
          customtype_name:"",
        },
        ruleaddtask: {
          taskname: [
            { required: true, message: '任务名称不能为空', trigger: 'blur' }
          ],
          tasktype: [
            { required: true, type: "number", message: '任务类型不能为空', trigger: 'blur' }
          ],
          playbook_name: [
            { required: true, message: 'playbook剧本不能为空', trigger: 'blur' }
          ],
          modular: [
            { required: true, message: '模块不能为空', trigger: 'blur' }
          ],
          source: [
            { required: true, message: '源不能为空', trigger: 'blur' }
          ],
          stepsize: [
            { required: true, type: "number", message: '步长不能为空', trigger: 'blur' }
          ],
          target: [
            { required: true, message: '目标不能为空', trigger: 'blur' }
          ],
          hosts: [
            { required: true, message: '机器不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      time_change(time_str) {
        return moment(time_str).format('YYYY-MM-DD HH:mm:ss')
      },

      selectplaybook (query) {
        if (query !== '') {
          this.loadingplaybook = true;
          this.queryplaybookinfo.name = query.trim()
          this.getPlaybooklist(this.queryplaybookinfo)
        }
      },

      selectcustomtype (query) {
        if (query !== '') {
          this.loadingtype = true;
          this.queryname.name = query.trim()
          this.getcustomtypelist(this.queryname)
        }
      },

      showpublishdrawer(row) {
        if (row["state"] === 5) {
          this.showpublish = true
          this.taskinfo = row
        } else {
          this.$Message.warning("该状态不能执行发布")
        }
      },

      namechange() {
        this.queryinfo.taskname = this.queryinfo.taskname.trim()
        this.queryinfo.created_user = this.queryinfo.created_user.trim()
        this.queryinfo.customtype_name = this.queryinfo.customtype_name.trim()
        this.queryinfo.page = 1
        this.getdata(this.queryinfo)
      },

      getdata(queryinfo) {
        const url = "/goansible/api/v1/task/info"
        get(url,queryinfo)
          .then((resp)=>{
            this.data = resp["data"];
            this.total = resp.count
          }).catch(e=>{
          return e
        })
      },
      showdetails(row) {
        if (row.state === 8) {
          this.$Message.warning('任务还在等待中');
        } else {
          const api = '/opsTools/AnsibleTools/tasklog/real/' + row.id
          this.$router.push(api);
        }
      },
      canceldelete () {},
      showupdata(row) {
				this.getPlaybooklist(this.queryplaybookinfo)
        this.showupdatatask = true
        this.updatetaskform = row

      },
      selectqueryinfo () {
        this.gettaskinfo(this.page,this.pagesize)
      },
      isdelete (row) {
        this.deltasklog = row
        this.showdelete = true
      },

      isstop (row) {
        this.deltasklog = row
        this.showstop = true
      },
      changepage (page) {
        this.queryinfo.page = page
        this.getdata(this.queryinfo)
      },

      changepagesize(pagesize) {
        this.queryinfo.page = 1
        this.queryinfo.pagesize = pagesize
        this.getdata(this.queryinfo)
      },

      resettask(row) {
        get(`/goansible/api/v1/task/reset?id=`+ row.id)
          .then((resp)=>{
            this.$Message['info']({background: true,content: resp.msg})
          }).catch(e=>{
          return e
        })
      },

      addtaskvalidate (name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.addtask(this.addtaskform)
            this.showaddtask = false
          } else {
            this.$Message.error("请完善表弟")
          }
        })
      },

      deletetasklog(id) {
        del(`/goansible/api/v1/task/submit?id=`+ id)
          .then((resp)=>{
            this.queryinfo.page = 1
            this.getdata(this.queryinfo)
          }).catch(e=>{
          return e
        })
      },

      updatetaskvalidate (name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.updatetask(this.updatetaskform.id,this.updatetaskform)
            this.showupdatatask = false
          } else {
            this.$Message.error("请完善表弟")
          }
        })
        this.getdata(this.queryinfo)
      },

      cancel () {
        this.showupdatatask = false
      },

      handleReset (name) {
        this.$refs[name].resetFields();
      },
      canceladd () {
        this.showaddtask = false
      },
      changemodule () {
        this.addtaskform.args = this.args[this.addtaskform.modular]
      },

      changeupdatemodule () {
        this.updatetaskform.args = this.args[this.updatetaskform.modular]
      },

      changetasktype_to_args () {
        if (this.addtaskform.tasktype === 2) {
          this.addtaskform.args = ""
          this.getPlaybooklist(this.queryplaybookinfo)
        } else (
          this.changemodule()
        )
      },
      getPlaybooklist(queryplaybookinfo) {
        get("/goansible/api/v1/ansible/playbook", queryplaybookinfo)
          .then((resp)=>{
            this.playbooklist = resp.data
            this.loadingplaybook = false
          }).catch(e=>{
          return e
        })
      },
      addtask(data) {
        post("/goansible/api/v1/task/submit", data)
          .then((resp)=>{
            if (resp.code !== 0) {
              this.$Message.error(resp.msg);
            } else {
              this.queryinfo.page = 1
              this.getdata(this.queryinfo)
            }
          })
          .catch((e)=>{
            console.log(e)
            return e
          })
      },

      publish(id) {
        get(`/goansible/api/v1/task/exec?id=`+ id)
            .then((resp)=>{
              const api = '/opsTools/AnsibleTools/tasklog/real/' + id
              console.log(api)
              this.$router.push(api);
            }).catch(e=>{
          return e
        })
      },

      get_stree() {
        const url = "/stree/stree/path/simple_list"
        get(url, "")
            .then((resp)=>{
              if (resp["code"] === 0) {
                this.streelist = resp["data"]
              } else {
                this.$Message.error("服务树请求失败")
              }
            }).catch(e=>{
          return e
        })
      },

      getcustomtypelist(queryname) {
        const url = "/goansible/api/v1/ansible/type"
        get(url, queryname)
          .then((resp)=>{
            this.customtypelist = resp.data
            this.loadingtype = false
          }).catch(e=>{
          return e
        })
      },

      updatetask(id,data) {
        put(`/goansible/api/v1/task/submit?id=`+ id,data)
          .then((resp)=>{
            this.$Message.info(resp.msg);
            this.gettaskinfo()
          })
          .catch((e)=>{
            return e
          })
      },
    },
    created() {
      this.getdata(this.queryinfo)
      this.getcustomtypelist(this.queryname)
      // this.get_stree()
    }
  }
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 150px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
